import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const CountryCodeSelect = ({ control, name, countryList, userDetails = null }) => {
   
    const options = countryList.map((country) => ({
        value: country.phonecode,
        label: `${country.phonecode} ${country.name}`,
    }));

    const [selectedCountry, setSelectedCountry] = useState('1');

    // Set the first country as default on component mount
    useEffect(() => {
        setSelectedCountry(userDetails?.country_code);
    }, [userDetails]);


    // Handle country change
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption?.value);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: "Please select Country Code." }}
            render={({ field, fieldState: { error } }) => (
                <>
                    <Select
                        {...field}
                        options={options}
                        value={options.find(option => option.value === selectedCountry)}
                        onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);  // Pass the value, not the whole object
                            handleCountryChange(selectedOption);   // Call your custom handler (optional)
                        }}
                        placeholder="Select"
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                    {error && <p role="alert" className="text-danger mobile-error">{error.message}</p>}
                </>
            )}
        />
    );
};

export default CountryCodeSelect;